export const PROTECTED_ROUTES = "/p";

export const LANDING = "/";
export const SETTINGS = PROTECTED_ROUTES + "/settings";
export const HELP = PROTECTED_ROUTES + "/help";
export const SUBMIT_BUG = PROTECTED_ROUTES + "/help/contactus";
export const SIGN_UP = "/signup";
export const PLAN = PROTECTED_ROUTES + "/plan";
export const CHECKOUT = PROTECTED_ROUTES + "/Checkout";
export const SIGN_IN = "/signin";
export const SUBSCRIPTION_CANCELLED =
  PROTECTED_ROUTES + "/subscriptioncancelled";
export const SIGN_OUT = PROTECTED_ROUTES + "/signout";
export const HOME = PROTECTED_ROUTES + "/home";
export const ACCOUNT = PROTECTED_ROUTES + "/account";
export const ADMIN = PROTECTED_ROUTES + "/admin";
export const PASSWORD_FORGET = "/pw-forget";
export const PASSWORD_CHANGE = PROTECTED_ROUTES + "/pw-change";

export const CAMPAIGNS = PROTECTED_ROUTES + "/providers";
export const CAMPAIGN_DETAIL = PROTECTED_ROUTES + "/providers/detail/";
export const CAMPAIGN_EDIT = PROTECTED_ROUTES + "/providers/edit/";
export const CAMPAIGN_NEW = PROTECTED_ROUTES + "/providers/new";

export const QRCODES = PROTECTED_ROUTES + "/menus";
export const QRCODE_EDIT = PROTECTED_ROUTES + "/menus/edit/";
export const QRCODE_NEW = PROTECTED_ROUTES + "/menus/new";
export const QRCODE_CUSTOMIZE = PROTECTED_ROUTES + "/menus/customize/";

export const QRCODESWEB = PROTECTED_ROUTES + "/menus/web";
export const QRCODESDOWNLOAD = PROTECTED_ROUTES + "/menus/download";
export const QRCODESFILE = PROTECTED_ROUTES + "/menus/pdf";

export const LAB_SAMPLES = PROTECTED_ROUTES + "/labsamples";

export const COLLECTOR = "/c";
export const COLLECTORLIST = PROTECTED_ROUTES + "/collector";
export const ERROR = "/error";
export const MENUS = PROTECTED_ROUTES + "/menubuilder";
export const MENU_PREVIEW = PROTECTED_ROUTES + "/preview/";
export const MENU_NEW = PROTECTED_ROUTES + "/menubuilder/new/";
export const MENU_EDIT = PROTECTED_ROUTES + "/menubuilder/edit/";
export const FIRSTSIGNINWIZARD = PROTECTED_ROUTES + "/welcome";
// export const QRCODECONTACTVIEW = "/c/:id";
