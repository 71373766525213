import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import { HashRouter as Router } from "react-router-dom";
import "./assets/base.scss";
import "./assets/my-assets/css/styles.css";
import Main from "./pages/main/mainApp";

import { Provider } from "react-redux";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore"; // <- needed if using firestore
import "firebase/functions"; // <- needed if using httpsCallable
// import { createStore, combineReducers, compose } from "redux";
// import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore"; // <- needed if using firestore

// import Firebase, { FirebaseContext } from "./pages/Firebase";

// import PersistedStore from "./reduxStore/config/NOTUSED-persistedStore";
import configureStore from "./reduxStore/config/configureStore";

const store = configureStore();

// react-redux-firebase config
const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  fileMetadataFactory: (uploadRes, firebase, metadata, downloadURL) => {
    // upload response from Firebase's storage upload
    const {
      metadata: { name, fullPath },
    } = uploadRes;
    // default factory includes name, fullPath, downloadURL
    return {
      name,
      fullPath,
      downloadURL,
    };
  },
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

const rootElement = document.getElementById("root");

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Router>
          <Component />
        </Router>
      </ReactReduxFirebaseProvider>
    </Provider>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept("./pages/main/mainApp", () => {
    const NextApp = require("./pages/main/mainApp").default;
    renderApp(NextApp);
  });
}
serviceWorker.unregister();
