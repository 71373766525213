import { Route, Redirect } from "react-router-dom";
// import { withFirebase } from "react-redux-firebase";
import { compose } from "redux";
// import { useSelector } from "react-redux";

import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";
import * as ROUTES from "../../constants/routes";
import { ToastContainer } from "react-toastify";
import AuthLoader from "../../db/AuthLoader";
// import AppMainProtected from "./protectedRoutes";

/****************************** UnProtected Routes ****************************** */
const SIGNUP = lazy(() => import("../User/SignUp/signup"));
const SIGNIN = lazy(() => import("../User/SignIn"));
const FORGOTPASSWORD = lazy(() => import("../User/PasswordForget"));
const COLLECTOR = lazy(() => import("../Collector/email"));
const ERROR = lazy(() => import("../../utils/Error"));

const fallBackDiv = (
  <div className="loader-container">
    <div className="loader-container-inner">
      <div className="text-center">
        <Loader type="ball-grid-beat" />
      </div>
      <h6 className="mt-3">Please wait while we process your request</h6>
    </div>
  </div>
);

const AppMain = (props) => {
  // console.log("AppMain");
  return (
    <Fragment>
      {/****************************** UnProtected Routes ****************************** */}
      {/* SignUp */}
      <Suspense fallback={fallBackDiv}>
        <Route path={ROUTES.SIGN_UP} component={SIGNUP} />
      </Suspense>

      {/* SignIn */}
      <Suspense fallback={fallBackDiv}>
        <Route path={ROUTES.SIGN_IN} component={SIGNIN} />
      </Suspense>

      {/* PasswordForget */}
      <Suspense fallback={fallBackDiv}>
        <Route path={ROUTES.PASSWORD_FORGET} component={FORGOTPASSWORD} />
      </Suspense>

      {/* Collector */}
      <Suspense fallback={fallBackDiv}>
        <Route path={ROUTES.COLLECTOR} component={COLLECTOR} />
      </Suspense>

      {/* Error */}
      <Suspense fallback={fallBackDiv}>
        <Route path={ROUTES.ERROR} component={ERROR} />
      </Suspense>

      <Suspense fallback={fallBackDiv}>
        <Route path={ROUTES.PROTECTED_ROUTES}>
          <AuthLoader />
        </Route>
      </Suspense>

      <Route exact path="/" render={() => <Redirect to="/signin/" />} />

      <ToastContainer />
    </Fragment>
  );
};

export default compose()(AppMain);
