import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import reducers from "..";
import thunk from "redux-thunk";
// import { createLogger } from 'redux-logger'
import { firestoreReducer } from "redux-firestore";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage"; // <- needed if using storage
import "firebase/firestore"; // <- needed if using firestore
import "firebase/functions"; // <- needed if using httpsCallable
import "firebase/analytics";
import firebaseReducer from "react-redux-firebase/lib/reducer";
const firebaseConfig = require(process.env
  .REACT_APP_SERVICE_FIREBASE_CONFIG_FILE);

//const loggerMiddleware = createLogger();

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);

// Initialize other services on firebase instance
firebase.firestore(); // <- needed if  using firestore
firebase.functions(); // <- needed if using httpsCallable
firebase.storage();
firebase.analytics();

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer, // <- needed if using firestore
  ...reducers,
});

// Create store with reducers and initial state
const initialState = {};

let allStoreEnhancers = null;

// if (process.env.REACT_APP_ENV === "Dev")
//   allStoreEnhancers = compose(
//     applyMiddleware(thunk),
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//   );
// else {
allStoreEnhancers = compose(applyMiddleware(thunk));
// }

const store = createStore(rootReducer, initialState, allStoreEnhancers);

export default () => {
  return store;
};
