import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { spinnerWhileLoading } from "../utils/isComponentLoading";
import ProtectedRoutes from "../pages/main/protectedRoutes";

const enhance = compose(
  connect(({ firebase: { profile, auth } }) => ({ profile, auth })),
  spinnerWhileLoading(["profile", "auth"]),
  firestoreConnect((props) => {
    const queries = [];
    if (
      props.auth.isLoaded &&
      !props.auth.isEmpty &&
      props.profile.isLoaded &&
      !props.profile.isEmpty &&
      props.profile.accountId
    ) {
      if (!props.campaigns) {
        console.log("querying db for campaigns");
        queries.push({
          collection: "campaigns",
          where: ["accountId", "==", props.profile.accountId],
          orderBy: ["datecreated", "desc"],
        });
      }
      if (!props.qrcodes) {
        console.log("querying db for qrcodes");
        queries.push({
          collection: "qrcodes",
          orderBy: ["datecreated", "desc"],
          where: ["accountId", "==", props.profile.accountId],
        });
      }
      console.log("querying db for sys_plans");
      queries.push({
        collection: "sys_plans",
        where: ["active", "==", true],
        orderBy: ["orderIndex", "asc"],
      });
    }
    return queries;
  }),
  connect((state) => ({
    campaigns: state.firestore.ordered.campaigns,
    qrcodes: state.firestore.ordered.qrcodes,
    sys_plans: state.firestore.ordered.sys_plans,
  })),
  spinnerWhileLoading(["campaigns", "qrcodes", "sys_plans"])
);

export default enhance(ProtectedRoutes);
