import React, { Suspense, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { spinnerWhileLoading } from "../utils/isComponentLoading";
import { Route, Redirect } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import Loader from "react-loaders";
import AppMainProtected from "./defaultDataLoad";

const fallBackDiv = (
  <div className="loader-container">
    <div className="loader-container-inner">
      <div className="text-center">
        <Loader type="ball-grid-beat" />
      </div>
      <h6 className="mt-3">Please wait while we process your request</h6>
    </div>
  </div>
);

const AuthLoader = (props) => {
  console.log("Entering AuthLoader");

  const { auth, profile } = props;

  const performQuery =
    auth.isLoaded &&
    !auth.isEmpty &&
    profile.isLoaded &&
    !profile.isEmpty &&
    profile.accountId;

  if (!performQuery) {
    return <Redirect to={ROUTES.SIGN_IN} />;
  } else {
    return (
      <Fragment>
        <Suspense fallback={fallBackDiv}>
          <Route path={ROUTES.PROTECTED_ROUTES}>
            <AppMainProtected />
          </Route>
        </Suspense>
      </Fragment>
    );
  }
};

const enhance = compose(
  connect(({ firebase: { profile, auth } }) => ({ profile, auth })),
  spinnerWhileLoading(["profile", "auth"])
);

export default enhance(AuthLoader);
