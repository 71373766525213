import React, { Component, Fragment } from "react";
import Loader from "react-loaders";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

class LoadersBasicExample extends Component {
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-grid-beat" />
              </div>
              <h6 className="mt-3">
                Please wait while we process your request
              </h6>
            </div>
          </div>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default LoadersBasicExample;
