export const FETCH_CAMPAIGNS_BEGIN = "campaign: FETCH_CAMPAIGNS_BEGIN";
export const FETCH_CAMPAIGNS_SUCCESS = "campaign: FETCH_CAMPAIGNS_SUCCESS ";
export const FETCH_CAMPAIGNS_FAILURE = "campaign: FETCH_CAMPAIGNS_ERROR";

export const UPDATE_CAMPAIGN_BEGIN = "campaign: UPDATE_CAMPAIGN_BEGIN";
export const UPDATE_CAMPAIGN_SUCCESS = "campaign: UPDATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN_FAILURE = "campaign: UPDATE_CAMPAIGN_FAILURE";

export const FETCH_PLAN_BEGIN = "plan: FETCH_PLAN_BEGIN";
export const FETCH_PLAN_SUCCESS = "plan: FETCH_PLAN_SUCCESS ";
export const FETCH_PLAN_FAILURE = "plan: FETCH_PLAN_ERROR";

export const SHOW_ERROR = "campaign: showError";

export const CHANGE_LOGGED_IN = "user: CHANGE_LOGGED_IN";
export const AUTH_USER_SET = "user: AUTH_USER_SET";
export const AUTH_USER_GET = "user: AUTH_USER_GET";

export const ADD_ITEM = "menu: ADD_ITEM";
export const DELETE_ITEM = "menu: DELETE_ITEM";
export const GET_ITEM = "menu: GET_ITEM";

export const CAMPAIGN_DELETE = "campaign: DELETE";
