import React, { Suspense, lazy, Fragment } from "react";
import { Route, withRouter } from "react-router-dom";

import Loader from "react-loaders";
import * as ROUTES from "../../constants/routes";
import { compose } from "redux";
// import { useSelector, connect } from "react-redux";
// import { spinnerWhileLoading } from "../../utils/isComponentLoading";
const HOME = lazy(() => import("../Dashboard"));
const Campaigns = withRouter(lazy(() => import("../campaigns")));
const QRCodes = lazy(() => import("../qr-codes"));
const SIGNOUT = lazy(() => import("../User/SignOut/autoSignOut"));
const MENUS = lazy(() => import("../Menu"));
const MENUPREVIEW = lazy(() => import("../Menu/Preview"));
const HELP = lazy(() => import("../Help"));
const COLLECTORLIST = lazy(() => import("../Collector"));
const SETTINGS = lazy(() => import("../User/Settings"));
const CHANGEPASSWORD = lazy(() => import("../User/PasswordChange"));
const PLAN = lazy(() => import("../Checkout/Plan"));
const CHECKOUT = lazy(() => import("../Checkout"));
const QRCODECONTACTVIEW = lazy(() =>
  import("../qr-codes/type/contact/qrcodeContactView")
);
const SUBSCRIPTIONCANCELLED = lazy(() =>
  import("../User/SubscriptionCancelled")
);
const FIRSTSIGNUPWIZARD = lazy(() => import("../Wizards"));

const fallBackDiv = (
  <div className="loader-container">
    <div className="loader-container-inner">
      <div className="text-center">
        <Loader type="ball-grid-beat" />
      </div>
      <h6 className="mt-3">Please wait while we process your request</h6>
    </div>
  </div>
);

// function PrivateRoute({ children, ...rest }) {
//   const auth = useSelector((state) => state.firebase.auth);
//   const profile = useSelector((state) => state.firebase.profile);
//   console.log(`auth: ${auth} profile: ${profile}`);
//   return (
//     <Route
//       {...rest}
//       {...profile}
//       render={({ location }) =>
//         isLoaded(auth) && !isEmpty(auth) ? (
//           children
//         ) : (
//           <Redirect
//             to={{
//               pathname: ROUTES.SIGN_IN,
//               state: { from: location },
//             }}
//           />
//         )
//       }
//     />
//   );
// }

const AppMainProtected = ({ campaigns, qrcodes }) => {
  // const auth = useSelector((state) => state.firebase.auth);
  // const isAuthorized = auth.emailVerified;
  return (
    <Fragment>
      {/* FIRSTSIGNUPWIZARD */}
      <Suspense fallback={fallBackDiv}>
        <Route path={ROUTES.FIRSTSIGNINWIZARD}>
          <FIRSTSIGNUPWIZARD />
        </Route>
      </Suspense>

      {/* SignOut */}
      <Suspense fallback={fallBackDiv}>
        <Route path={ROUTES.SIGN_OUT}>
          <SIGNOUT />
        </Route>
      </Suspense>

      {/* Homepage */}

      <Suspense fallback={fallBackDiv}>
        <Route path={ROUTES.HOME}>
          <HOME campaigns={campaigns} qrcodes={qrcodes} />
        </Route>
      </Suspense>

      {/* Campaigns */}
      <Suspense fallback={fallBackDiv}>
        <Route path={ROUTES.CAMPAIGNS}>
          <Campaigns campaigns={campaigns} qrcodes={qrcodes} />
        </Route>
      </Suspense>

      {/* QRCodes */}
      <Suspense fallback={fallBackDiv}>
        <Route path={ROUTES.QRCODES}>
          <QRCodes campaigns={campaigns} qrcodes={qrcodes} />
        </Route>
      </Suspense>

      {/* Plan */}
      <Suspense fallback={fallBackDiv}>
        <Route path={ROUTES.PLAN}>
          <PLAN />
        </Route>
      </Suspense>

      {/* Checkout */}
      <Suspense fallback={fallBackDiv}>
        <Route path={ROUTES.CHECKOUT}>
          <CHECKOUT />
        </Route>
      </Suspense>

      {/* ChangePassword */}
      <Suspense fallback={fallBackDiv}>
        <Route path={ROUTES.PASSWORD_CHANGE}>
          <CHANGEPASSWORD />
        </Route>
      </Suspense>

      {/* CONTACT CARD */}
      <Suspense fallback={fallBackDiv}>
        <Route path="/contact/:id">
          <QRCODECONTACTVIEW />
        </Route>
      </Suspense>

      {/* CollectorList */}
      <Suspense fallback={fallBackDiv}>
        <Route path={ROUTES.COLLECTORLIST} component={COLLECTORLIST} />
      </Suspense>

      {/* Settings */}
      <Suspense fallback={fallBackDiv}>
        <Route path={ROUTES.SETTINGS}>
          <SETTINGS />
        </Route>
      </Suspense>

      {/* MENU Preview */}
      <Suspense fallback={fallBackDiv}>
        <Route path={ROUTES.MENU_PREVIEW}>
          <MENUPREVIEW />
        </Route>
      </Suspense>

      {/* MENUS */}
      <Suspense fallback={fallBackDiv}>
        <Route path={ROUTES.MENUS}>
          <MENUS />
        </Route>
      </Suspense>

      {/* SubscriptionCancelled */}
      <Suspense fallback={fallBackDiv}>
        <Route path={ROUTES.SUBSCRIPTION_CANCELLED}>
          <SUBSCRIPTIONCANCELLED />
        </Route>
      </Suspense>

      {/* Help */}
      <Suspense fallback={fallBackDiv}>
        <Route path={ROUTES.HELP}>
          <HELP />
        </Route>
      </Suspense>
    </Fragment>
  );
};

export default compose()(AppMainProtected);
// connect(({ firebase: { auth, profile } }) => {
//   return {
//     auth: auth,
//     profile: profile, // profile passed as props.profile
//   };
// }),
// spinnerWhileLoading(["auth", "profile"])
