import {
  FETCH_PLAN_BEGIN,
  FETCH_PLAN_SUCCESS,
  FETCH_PLAN_FAILURE,
  SHOW_ERROR
} from "../actions/actionTypes";

const initialState = {
  data: [],
  loading: false,
  error: null
};
export default function planReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_PLAN_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_PLAN_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        loading: false,
        data: payload
      };

    case FETCH_PLAN_FAILURE:
      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have items to display anymore, so set `items` empty.
      //
      // This is all up to you and your app though:
      // maybe you want to keep the items around!
      // Do whatever seems right for your use case.
      return {
        ...state,
        loading: false,
        error: payload,
        data: []
      };

    case SHOW_ERROR:
      return payload;
    default:
      return state;
  }
}
